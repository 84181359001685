<template>
  <b-card
    class="detail-card"
    header-border-variant="white"
    header-bg-variant="white"
    :header="formatHeader(attributeName)"
  >
    <b-card-text class="detail-card-text">
      {{ formattedAttribute }}
    </b-card-text>
  </b-card>
</template>
<script>
  import { phoneNumberFormatter } from '@/mixins/phoneNumberFormatter'
  export default {
    name: 'DetailCard',
    mixins: [phoneNumberFormatter],
    props: {
      attributeName: String,
      attributeValue: String,
    },
    computed: {
      formattedAttribute() {
        if (this.attributeName === 'phone_number') {
          return this.formatPhoneNumber()
        }

        return this.attributeValue
      },
    },
    methods: {
      formatHeader(attributeName) {
        return attributeName
          .split('_')
          .map((str) =>  str.charAt(0).toUpperCase() + str.slice(1) )
          .join(' ')
      },
      formatPhoneNumber() {
        if (this.attributeValue.length === 10) {
          return this.formatWithAreaCode(this.attributeValue)
        }

        return this.attributeValue
      },
    },
  }
</script>

<style lang="scss" scoped>
.detail-card {
  height: 100%;
  font-size: 0.875em;
  color: #7C7C7C;

  .detail-card-text {
    font-size: 1.0em;
    color: #363636;
  }
}
</style>


