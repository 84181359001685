var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "detail-card",
      attrs: {
        "header-border-variant": "white",
        "header-bg-variant": "white",
        header: _vm.formatHeader(_vm.attributeName),
      },
    },
    [
      _c("b-card-text", { staticClass: "detail-card-text" }, [
        _vm._v("\n    " + _vm._s(_vm.formattedAttribute) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }